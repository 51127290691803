import {  Menu, Row, Col, Space, Typography } from "antd";
import  {  MenuOutlined, UserOutlined, SettingOutlined, CalendarOutlined } from "@ant-design/icons";
import { signOut } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
const {Title} = Typography
const AppHeader = () => {
  const navigate = useNavigate();
  let MenuItems = [
    {
      label: "Menu",
      key: "mail",
      selectedKeys: ["/app-manager"],
      icon: <MenuOutlined />,
      children: [
        {
          label: "Admin Dashboard",
          key: "/",
        },
        {
          label: "Management Dashboard",
          key: "/dashboard",
        },
        {
          label: "New Procurement",
          key: "/cluster/initialize",
        },
        {
          label: "Sizing",
          key: "/sizing",
        },
      ],
    },
  ];
  let UserMenuItems = [
    {
      label: "User",
      key: "mail",
      selectedKeys: ["/logout"],
      icon: <UserOutlined style={{ fontSize: "18px" }} />,
      children: [

        {
          label: <span onClick={signOut}>Logout</span>,
          key: "/logout",
        },
      ],
    },
  ]
  return (
    <>
      <Row align={"middle"} justify={"center"} style={{}}>
        <Col span={24}>
          <Row align={"middle"} justify={"center"} style={{ background: "#00857C", maxHeight: "150px" }}>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4} >
              <img src="/images/logo.png" alt="logo" style={{ maxWidth: "125px", maxHeight: "150px" }} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <Title level={3} style={{ color: "white", textAlign: 'center' }}>ROBO PROCUREMENT PLATFORM</Title>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}></Col>
          </Row>
        </Col>
        <Col span={24} style={{ backgroundColor: "white" }}>
          <Row gutter={[]} justify="end" align="middle" style={{ background: "white", minHeight: "65px" }}>
            <Col xs={24} sm={24} md={6} span={6}>
              <Space direction="horizontal" size={"large"}>
                <Menu onClick={(e) => {navigate(e.key) }} mode={"horizontal"} theme={"light"} items={MenuItems}></Menu>
                <SettingOutlined style={{ fontSize: "21px" }} />
                <CalendarOutlined style={{ fontSize: "21px" }} />

                <Menu
                  onClick={(e) => { }}
                  mode={"horizontal"}
                  theme={"light"}
                  items={UserMenuItems}></Menu>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      &nbsp;
    </>
  );
};

export default AppHeader;
