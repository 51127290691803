import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
const style = {
  display: "flex",
  flexDirection: "column",
  gap: 15,
  fontWeight: 450,
};

const { Text } = Typography;
const ActionPage = () => {
  const [value, setValue] = useState("");
  const [scNumber, setSCNumber] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleRadioOnChange = (e) => {
    setValue(e.target.value);
  };

  const handleSCNoChange = (e)=>{
    setSCNumber(e.target.value);
  }

  return (
    <Form>
        <Row>
          <Col span={24}>
            <Radio.Group
              style={style}
              onChange={handleRadioOnChange}
              defaultValue={1}
            >
              <Radio value={1}>No new CommVault resources required</Radio>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8%",
                }}
              >
                <Radio value={2}>SCTask# for new resources</Radio>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Text strong>Enter SC : </Text>
                  <Input
                    disabled={value !== 2}
                    placeholder="Enter no"
                    value={scNumber}
                    onChange={(e)=>handleSCNoChange(e)}
                    style={{ width: 180 }}
                  />
                </div>
              </div>
            </Radio.Group>
          </Col>
        </Row>
        {value === 2 && (
          <Row>
            <Col span={24} style={{ marginTop: "35px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10%",
                }}
              >
                <Upload 
                 showUploadList={false} 
                 beforeUpload={(file) => {
                   setUploadedFile(file.name);
                   return false; 
                 }}>
                  <Button type="primary" icon={<UploadOutlined />}>
                    Upload Quote File
                  </Button>
                </Upload>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Text strong style={{ color: "black" }}>
                    Quote File:
                  </Text>
                  <Text
                    strong
                    style={{ color: uploadedFile ? "teal" : "gray" }}
                  >
                    {uploadedFile || "No file uploaded"}
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Space style={{ marginTop: "35px" }}>
              <Form.Item>
                <Button type="primary">Assessment Complete</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary">Validate</Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
    </Form>
  );
};

export default ActionPage;
