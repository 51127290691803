import {fetchAuthSession} from '@aws-amplify/auth';
import AWS, {DynamoDB} from 'aws-sdk';
import {handleError} from './error_handler';
import moment from "moment/moment";
import {getUserIdentity} from "./auth";

interface Credentials {
  accessKeyId: string;
  secretAccessKey: string;
  region: string;
  sessionToken: string
}

interface DB_RESPONSE {
  success: boolean,
  code: number | string
  data: any,
  error?: Error | null,
  nextStartKey?: any
}

//old
// interface ProcurementItem {
//   PK: string;
//   SK: string;
//   procurement_name?: string;
//   procurement_type?: string;
//   created_at?: string;
// }

// interface AggregatedProcurement {
//   procurement_name: string;
//   procurement_type: string;
//   general_created_at: string;
//   network_created_at: string;
//   commvault_created_at: string;
//   site_created_at: string;
//   sizing_created_at: string;
// }

//new
interface ProcurementItem {
  PK: string;
  SK: string;
  procurement_name?: string;
  procurement_type?: string;
  created_at?: string;
  completed_at?: string;
  stage_status?: string;
}

interface AggregatedProcurement {
  PK: string;
  procurement_name: string;
  procurement_type: string;
  created_at: string;
  completed_at?: string;
  network_created_at: string;
  network_completed_at: string;
  network_status: string;
  commvault_created_at: string;
  commvault_completed_at: string;
  commvault_status: string;
  site_created_at: string;
  site_completed_at: string;
  site_status: string;
  sizing_created_at: string;
  sizing_completed_at: string;
  sizing_status: string;
}



export class DatabaseManager {

  private dynamoDBService: DynamoDB.DocumentClient = new AWS.DynamoDB.DocumentClient();
  private credential_type: 'amplify' | 'manual' = 'amplify'
  private credentials: Credentials | null = null

  constructor(credential_type?: 'amplify' | 'manual', credential_obj?: Credentials) {
    if (credential_type === 'manual' && credential_obj) {
      this.credentials = credential_obj
      this.configureDynamoDBService();
    } else {
      // Initialize DynamoDB service without explicit credentials (use default credentials provider chain)
      this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
    }
  }

  private async configureDynamoDBService(): Promise<any> {
    // Configure DynamoDB service with provided credentials
    if (this.credential_type == 'manual') {
      AWS.config.update({
        accessKeyId: this.credentials?.accessKeyId,
        secretAccessKey: this.credentials?.secretAccessKey,
        region: this.credentials?.region ? this.credentials.region : 'us-east-1',
        sessionToken: this.credentials?.sessionToken
      });
    }
    if (this.credential_type == 'amplify') {
      const session = await fetchAuthSession()
      //@ts-ignore
      AWS.config.update({...session.credentials, region: process.env.REACT_APP_AWS_DEFAULT_REGION})
    }

    // Create DynamoDB service object
    this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#put-property
   * @param tableName
   * @param item
   */
  public async createObject(
    tableName: string,
    item: any): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.Put = {
      TableName: tableName,
      Item: {
        ...item,
        "created_at": moment.utc().toISOString(),
        "created_by": (await getUserIdentity()).email,
      }
    };

    try {
      await this.configureDynamoDBService()
      await this.dynamoDBService.put(params).promise();
      return {
        success: true,
        error: null,
        data: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#scan-property
   * @param tableName
   * @param filterExpression
   * @param expressionAttributeNames
   * @param expressionAttributeValues
   * @param projectExpression
   * @param limit
   * @param startKey
   */
  public async getAllObjects(
    tableName: string,
    filterExpression?: string | undefined,
    expressionAttributeNames?: any | undefined,
    expressionAttributeValues?: any | undefined,
    projectExpression?: string | undefined,
    limit?: number | undefined,
    startKey?: any | undefined): Promise<DB_RESPONSE> {

    await this.configureDynamoDBService();
    let params: DynamoDB.DocumentClient.ScanInput = {
      TableName: tableName,
      ProjectionExpression: projectExpression,
      FilterExpression: filterExpression,
      ExpressionAttributeNames: expressionAttributeNames,
      ExpressionAttributeValues: expressionAttributeValues,
      Limit: limit,
      ExclusiveStartKey: startKey
    };

    try {
      let scanData: any[] = []
      do {
        const response = await this.dynamoDBService.scan(params).promise();

        if (response.Items) {
          response.Items.forEach(item => {
            scanData.push(item);
          });
        }

        params = {
          ...params,
          ExclusiveStartKey: response.LastEvaluatedKey
        }
      } while (params.ExclusiveStartKey);

      return {
        success: true,
        code: 200,
        data: scanData,
      }
    } catch (error: any) {
      handleError(error);
      return {
        success: false,
        code: 500,
        error: error,
        data: []
      }
    }
  }

  /**
   * See https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#get-property
   * @param tableName
   * @param key
   * @param projectionExpression
   */
  public async getObjectById(
    tableName: string,
    key: any,
    projectionExpression?: string | undefined): Promise<DB_RESPONSE> {
    await this.configureDynamoDBService()
    const params: DynamoDB.DocumentClient.GetItemInput = {
      TableName: tableName,
      ProjectionExpression: projectionExpression,
      Key: key
    };

    try {
      const data = await this.dynamoDBService.get(params).promise();
      if (!data.Item) {
        return {
          data: null,
          error: new Error(`RECORD_NOT_FOUND`),
          success: false,
          code: 401
        }
      }
      return {
        data: data.Item,
        success: true,
        error: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * See https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#query-property
   * @param tableName
   * @param keyConditionExpression
   * @param filterExpression
   * @param expressionAttributeValues
   * @param projectionExpression
   */
  public async queryTable(
    tableName: string,
    keyConditionExpression?: string | undefined,
    filterExpression?: string | undefined,
    expressionAttributeValues?: any | undefined,
    projectionExpression?: string | undefined): Promise<DB_RESPONSE> {

    await this.configureDynamoDBService()
    if (tableName == undefined) {
      return {
        data: null,
        success: false,
        error: new Error('tableName is undefined'),
        code: 500
      }
    }

    let params: DynamoDB.DocumentClient.QueryInput = {
      TableName: tableName,
      ProjectionExpression: projectionExpression,
      KeyConditionExpression: keyConditionExpression,
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues
    };

    try {
      let scanData: any[] = []
      do {
        const response = await this.dynamoDBService.query(params).promise();

        if (response.Items) {
          response.Items.forEach(item => {
            scanData.push(item);
          });
        }

        params = {
          ...params,
          ExclusiveStartKey: response.LastEvaluatedKey
        }
      } while (params.ExclusiveStartKey);

      return {
        data: scanData,
        success: true,
        error: null,
        code: 200
      }
    } catch (error: any) {
      handleError(error)
      return {
        success: false,
        error,
        data: null,
        code: 500
      }
    }
  }

  public async updateObject(
    tableName: string,
    item: any): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.Put = {
      TableName: tableName,
      Item: {
        ...item,
        "updated_at": moment.utc().toISOString(),
        "updated_by": (await getUserIdentity()).email,
      }
    };

    try {
      await this.configureDynamoDBService()
      await this.dynamoDBService.put(params).promise();
      return {
        success: true,
        error: null,
        data: null,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#update-property
   * @param tableName
   * @param key
   * @param updateExpression
   * @param conditionExpression
   * @param expressionAttributeNames
   * @param expressionAttributeValues
   */
  public async updateRecord(
    tableName: string,
    key: any,
    updateExpression: string,
    conditionExpression?: string | undefined,
    expressionAttributeNames?: any | undefined,
    expressionAttributeValues?: any | undefined): Promise<DB_RESPONSE> {

    const params: DynamoDB.DocumentClient.UpdateItemInput = {
      TableName: tableName,
      Key: key,
      UpdateExpression: updateExpression,
      ConditionExpression: conditionExpression,
      ExpressionAttributeNames: expressionAttributeNames,
      ExpressionAttributeValues: expressionAttributeValues,
      ReturnValues: 'UPDATED_NEW'
    };

    try {
      await this.configureDynamoDBService()
      const response = await this.dynamoDBService.update(params).promise();
      return {
        success: true,
        error: null,
        data: response.Attributes,
        code: 200
      }
    } catch (error: any) {
      return {
        success: false,
        error: error,
        data: null,
        code: 500
      }
    }
  }

  /**
   * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html#delete-property
   * @param tableName
   * @param keys
   * @param conditionalExpression
   * @param expressionAttributeValues
   */
  public async deleteObject(
    tableName: string,
    keys: any,
    conditionalExpression?: any | undefined,
    expressionAttributeValues?: any | undefined): Promise<void> {
    const params: DynamoDB.DocumentClient.Delete = {
      TableName: tableName,
      Key: keys,
      ConditionExpression: conditionalExpression,
      ExpressionAttributeValues: expressionAttributeValues
    };

    try {
      await this.dynamoDBService.delete(params).promise();
    } catch (error: any) {
      handleError(error)
    }
  }

  public getDynamoDBService = () => {
    return this.dynamoDBService
  }

  //old
  // public async aggregateAllProcurementData(
  //   tableName: string
  // ): Promise<{ success: boolean; error: any; data: AggregatedProcurement[] | null; code: number }> {
  //   try {
  //     console.log("Starting data aggregation...");
  //     await this.configureDynamoDBService();

  //     // Scan the table
  //     const params: AWS.DynamoDB.DocumentClient.ScanInput = {
  //       TableName: tableName,
  //     };

  //     let items: ProcurementItem[] = [];
  //     let lastEvaluatedKey: AWS.DynamoDB.DocumentClient.Key | undefined = undefined;

  //     do {
  //       console.log("Scanning DynamoDB...");
  //       const result: AWS.DynamoDB.DocumentClient.ScanOutput = await this.dynamoDBService
  //         .scan({ ...params, ExclusiveStartKey: lastEvaluatedKey })
  //         .promise();

  //       if (!result.Items || result.Items.length === 0) {
  //         console.log("No items found in this scan iteration.");
  //         break;
  //       }

  //       console.log(`Fetched ${result.Items.length} items in this scan iteration.`);
  //       console.log("PKs in this batch:", result.Items.map(item => item.PK));

  //       items = items.concat(result.Items as ProcurementItem[]);
  //       lastEvaluatedKey = result.LastEvaluatedKey;
  //     } while (lastEvaluatedKey);

  //     console.log(`Total items retrieved: ${items.length}`);

  //     if (items.length === 0) {
  //       console.log("No data found in DynamoDB. Returning empty result.");
  //       return { success: true, error: null, data: [], code: 200 };
  //     }

  //     // Group items by PK
  //     const groupedItems: Record<string, ProcurementItem[]> = {};
  //     for (const item of items) {
  //       if (!item.PK) {
  //         console.warn("Skipping an item with missing PK:", item);
  //         continue;
  //       }

  //       if (!groupedItems[item.PK]) {
  //         groupedItems[item.PK] = [];
  //       }
  //       groupedItems[item.PK].push(item);
  //     }

  //     console.log(`Total unique PKs found: ${Object.keys(groupedItems).length}`);
  //     console.log("Unique PKs:", Object.keys(groupedItems));

  //     if (Object.keys(groupedItems).length === 0) {
  //       console.error("Grouping resulted in zero unique PKs! Something is wrong.");
  //       return { success: false, error: "No valid PKs found.", data: null, code: 500 };
  //     }

  //     const aggregatedArray: AggregatedProcurement[] = [];

  //     for (const PK in groupedItems) {
  //       console.log(`Processing PK: ${PK}`);
  //       const group = groupedItems[PK];

  //       // **Find base item where PK === SK**
  //       const baseItem = group.find(item => item.PK === item.SK);

  //       if (!baseItem) {
  //         console.warn(`Skipping PK ${PK} as no base item (PK === SK) found.`);
  //         continue;
  //       }

  //       console.log(`Base item for ${PK}:`, baseItem);

  //       const aggregated: AggregatedProcurement = {
  //         procurement_name: baseItem.procurement_name || "not_available",
  //         procurement_type: baseItem.procurement_type || "not_available",
  //         general_created_at: baseItem.created_at || "not_available",
  //         network_created_at: "not_available",
  //         commvault_created_at: "not_available",
  //         site_created_at: "not_available",
  //         sizing_created_at: "not_available",
  //       };

  //       // Process stage objects
  //       for (const item of group) {
  //         if (!item.SK.startsWith("STAGE|")) continue;

  //         const stageParts = item.SK.split("|");
  //         if (stageParts.length < 2) continue;

  //         const stage = stageParts[1].toLowerCase();
  //         console.log(`Processing stage: ${stage} for PK: ${PK}`);

  //         switch (stage) {
  //           case "network":
  //             aggregated.network_created_at = item.created_at || "not_available";
  //             break;
  //           case "commvault":
  //             aggregated.commvault_created_at = item.created_at || "not_available";
  //             break;
  //           case "site":
  //             aggregated.site_created_at = item.created_at || "not_available";
  //             break;
  //           case "sizing":
  //             aggregated.sizing_created_at = item.created_at || "not_available";
  //             break;
  //         }
  //       }

  //       console.log(`Aggregated data for PK ${PK}:`, aggregated);
  //       aggregatedArray.push(aggregated);
  //     }

  //     console.log("Final aggregated array:", JSON.stringify(aggregatedArray, null, 2));

  //     return {
  //       success: true,
  //       error: null,
  //       data: aggregatedArray,
  //       code: 200,
  //     };
  //   } catch (error) {
  //     console.error("Error during aggregation:", error);
  //     return {
  //       success: false,
  //       error: error,
  //       data: null,
  //       code: 500,
  //     };
  //   }
  // }

  //new
  public async aggregateAllProcurementData(tableName: string): Promise<{ success: boolean; error: any; data: AggregatedProcurement[] | null; code: number }> {
    try {
      await this.configureDynamoDBService();

      // Scan the table
      const params: AWS.DynamoDB.DocumentClient.ScanInput = {
        TableName: tableName,
      };

      let items: ProcurementItem[] = [];
      let lastEvaluatedKey: AWS.DynamoDB.DocumentClient.Key | undefined = undefined;

      do {
        const result: AWS.DynamoDB.DocumentClient.ScanOutput = await this.dynamoDBService
          .scan({ ...params, ExclusiveStartKey: lastEvaluatedKey })
          .promise();

        items = items.concat(result.Items as ProcurementItem[]);
        lastEvaluatedKey = result.LastEvaluatedKey;
      } while (lastEvaluatedKey);

      console.log("Total items fetched:", items.length);

      // Group by PK
      const groupedItems: Record<string, ProcurementItem[]> = items.reduce((acc: Record<string, ProcurementItem[]>, item: ProcurementItem) => {
        if (!acc[item.PK]) {
          acc[item.PK] = [];
        }
        acc[item.PK].push(item);
        return acc;
      }, {});

      console.log("Total unique PKs found:", Object.keys(groupedItems).length);

      const aggregatedArray: AggregatedProcurement[] = [];

      for (const pk in groupedItems) {
        const group = groupedItems[pk];

        console.log(`Processing PK: ${pk} with ${group.length} records`);

        const baseItem = group.find(item => item.PK === item.SK);
        if (!baseItem) {
          // console.warn(`Skipping PK ${pk} because no base item found.`);
          continue;
        }

        const aggregated: AggregatedProcurement = {
          PK: baseItem.PK,
          procurement_name: baseItem.procurement_name || "",
          procurement_type: baseItem.procurement_type || "",
          created_at: baseItem.created_at || "",
          network_created_at: "",
          network_completed_at: "",
          network_status: "",
          commvault_created_at: "",
          commvault_completed_at: "",
          commvault_status: "",
          site_created_at: "",
          site_completed_at: "",
          site_status: "",
          sizing_created_at: "",
          sizing_completed_at: "",
          sizing_status: "",
        };

        group.forEach(item => {
          if (item.SK.startsWith("STAGE|")) {
            const stage = item.SK.split("|")[1].toLowerCase();
            switch (stage) {
              case "network":
                aggregated.network_created_at = item.created_at || "";
                aggregated.network_completed_at = item.completed_at || "";
                aggregated.network_status = item.stage_status || "";
                break;
              case "commvault":
                aggregated.commvault_created_at = item.created_at || "";
                aggregated.commvault_completed_at = item.completed_at || "";
                aggregated.commvault_status = item.stage_status || "";
                break;
              case "site":
                aggregated.site_created_at = item.created_at || "";
                aggregated.site_completed_at = item.completed_at || "";
                aggregated.site_status = item.stage_status || "";
                break;
              case "sizing":
                aggregated.sizing_created_at = item.created_at || "";
                aggregated.sizing_completed_at = item.completed_at || "";
                aggregated.sizing_status = item.stage_status || "";
                break;
              default:
                break;
            }
          }
        });

        aggregatedArray.push(aggregated);
      }

      return {
        success: true,
        error: null,
        data: aggregatedArray,
        code: 200,
      };
    } catch (error) {
      console.error("Error during aggregation:", error);
      return {
        success: false,
        error: error,
        data: null,
        code: 500,
      };
    }
  }


}


export function getUpdateParamsFromPath(path: string, value: any) {
  let pathSegments = path.split('.')
  let _pathSegments = pathSegments.map((seg) => '#' + seg.replaceAll(/[ \/]/g, '_'))
  let result: any = {
    UpdateExpression: '',
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {}
  }

  pathSegments.forEach((seg, idx) => {
    result.ExpressionAttributeNames[_pathSegments[idx]] = seg
  })
  result.UpdateExpression = 'SET ' + _pathSegments.join('.') + ' = :value'
  result.ExpressionAttributeValues = {':value': value}
  return result

}



