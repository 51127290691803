import React from "react";
import DataComponent from "components/common/DataComponent";
import ActionPage from "./ActionPage";

const CommVaultSection = () => {
  return (
    <div
      id="site-section"
      style={{
        backgroundColor: "white",
        padding: "40px",
        border: "3px solid #f6f6f6",
        borderRadius: "10px",
      }}
    >
      <DataComponent />
      <ActionPage />
    </div>
  );
};

export default CommVaultSection;
