export const PROCUREMENT_TYPES = {
  NEW: "NEW",
  EXPANSION: "EXPANSION",
  REFRESH: "REFRESH",
};
export const SK_SEPERATOR = "|";

export const SK_PROC_STAGE_PREFIX = "STAGE" + SK_SEPERATOR;

export const PROCUREMENT_STATUS = {
  Initial: "Initial",
  Submitted: "Submitted",
  Approved: "Approved",
};

export const ENTITY_TYPES = {
  Procurement: "Procurement",
  ProcurementStage: "ProcurementStage",
};

export const PROCUREMENT_STAGES = {
  Sizing: "Sizing",
  Network: "Network",
  CommVault: "CommVault",
  BOM: "BOM",
  Site: "Site",
};

export const PROCUREMENT_STAGES_KEY = {
  Sizing: SK_PROC_STAGE_PREFIX + PROCUREMENT_STAGES.Sizing,
  Network: SK_PROC_STAGE_PREFIX + PROCUREMENT_STAGES.Network,
  CommVault: SK_PROC_STAGE_PREFIX + PROCUREMENT_STAGES.CommVault,
  BOM: SK_PROC_STAGE_PREFIX + PROCUREMENT_STAGES.BOM,
};

export const PROCUREMENT_STAGES_MAP = {
  DEMAND_MANAGEMENT: {
    DEMAND_INTAKE: "DEMAND_INTAKE",
    SIZING: "SIZING",
    "BILL OF MATERIALS": "BILL OF MATERIALS",
    QUOTE: "QUOTE",
  },
};

export const PROCUREMENT_STAGES_STATUS_COLOR = {
  Initial: "#f9ff99", // Yellow with 50% transparency
  // Submitted: "rgba(22, 184, 44, 0.5)", // Green with 50% transparency
  Submitted: "#74cbbc", // Green with 50% transparency
  Approved: "#66a6ff",
};
