import { Col, Row, Spin } from "antd"; 
import { Routes, Route, useLocation } from "react-router-dom"; 
import { Suspense, lazy, useEffect, useState } from "react"; 

import SizingSection from "./SizingSection";
import ProcurementList from "./ProcurementList/ProcurementList";
const ProcurementsListPage = lazy(() => import("./ProcurementsListPage")); 
const IntakeWizard = lazy(() => import("./IntakeWizard")); 
const BOMDataPage = lazy(() => import("./BOMDataPage")); 
const Dashboard = lazy(()=>import("./Dashboard/Dashboard"))
const Sizing = lazy(()=>import("./Sizing"))

const AppRoutes = () => { 
  const [contentSpan, setContentSpan] = useState(24); 
 
  return ( 
    <> 
      <Row align={"middle"} justify="center"> 
        <Col xs={contentSpan} sm={contentSpan} md={contentSpan} lg={contentSpan} xl={contentSpan}> 
          <Row 
            align="top" 
            style={{ 
              minHeight: '200vh', 
              background: "white", 
              // maxHeight: '200vh', 
              overflowY: 'auto' 
            }} 
          > 
            <Col span={23}> 
              <Suspense fallback={<Spin spinning={true} fullscreen />}> 
                &nbsp; 
                <Routes> 
                  {/* <Route path="/" element={<ProcurementsListPage />}></Route>  */}
                  <Route path="/cluster/initialize" element={<IntakeWizard />}></Route> 
                  <Route path="/bom/info" element={<BOMDataPage />}></Route> 
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                  <Route path="/procurement" element={<SizingSection />}></Route>
                  <Route path="/sizing" element={<Sizing />}></Route>
                  <Route path="/" element={<ProcurementList />}></Route>
                </Routes> 
              </Suspense> 
              &nbsp; 
            </Col> 
          </Row> 
        </Col> 
      </Row> 
    </> 
  ); 
}; 
export default AppRoutes; 
 
