import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  FileAddTwoTone,
  DeleteTwoTone,
  QuestionCircleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DatabaseManager } from "helpers/db";
import { deleteProcurement } from "apis";
import { ENTITY_TYPES, PROCUREMENT_STAGES_STATUS_COLOR } from "types";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const dbManager = new DatabaseManager();


const fetchProcurements = async () => {
  let filterExpression = `entity_type = :entityTypeValue`;
  let expressionAttributeValues = {
    ":entityTypeValue": ENTITY_TYPES.Procurement,
  };
  let response = await dbManager.getAllObjects(
    process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || "",
    filterExpression,
    undefined,
    expressionAttributeValues,
    undefined
  );

  return response.data;
};

const get_url = (procurement) => {
  return `/procurement?PK=${procurement["PK"]}&SK=${procurement["PK"]}&stage=Sizing`;
};

const ProcurementList = ({ filteredProcurementData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [procurementData, setProcurementData] = useState([]);
  const [deleteProcurementModal, setDeleteProcurementModal] = useState(false);
  const [isProcurementDeleted, setIsProcurementDeleted] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const handleDeleteProcurement = async (procurement) => {
    setDeleteProcurementModal(true);
    try {
      await deleteProcurement(procurement["PK"]);
    } catch (error) {
      console.error("Procurement deletion failed");
    } finally {
      setDeleteProcurementModal(false);
      setIsProcurementDeleted(true);
    }
  };

  const procurements_data = async () => {
    let res = dbManager.aggregateAllProcurementData(
      process.env.REACT_APP_DYNAMO_DB_PROCUREMENTS || ""
    );

    return res;
  };

  useEffect(() => {
    // newData().then((res) => {
    //   console.log("res", JSON.stringify(res));
    // });

    // fetchProcurements()
    //   .then((items) => {
    //     setProcurementData(items);
    //   })
    procurements_data()
      .then((res) => {
        setProcurementData(res?.data);
      })
      .catch((error) => {
        notification.error({
          message: "Error fetching procurements",
        });
      });

    location.pathname === "/dashboard" &&
      setProcurementData(filteredProcurementData);
  }, [isProcurementDeleted]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "procurement_name",
        header: "Procurement",
      },
      {
        accessorKey: "procurement_type",
        header: "Type",
      },
      {
        accessorKey: "created_at",
        header: "Created at",
        Cell: ({ row }) => (
          <Typography>
            {moment(row.original.created_at).format("MM/DD/YY, h:mm A")}
          </Typography>
        ),
      },
      
      {
        accessorKey: "sizing_created_at",
        header: "Sizing",
        muiTableBodyCellProps: ({
          cell, row
        }) => ({
          sx: {
            backgroundColor: PROCUREMENT_STAGES_STATUS_COLOR[row.original.sizing_status] , 
            padding: '10px', // Add padding for internal spacing
            border: '1.5px solid #fff', 
          }
        }),
        Cell: ({ row }) => (
        
            <Button
              type="link"
              onClick={() =>
                navigate(`/procurement?PK=${row.original.PK}&stage=Sizing`)
              }
              style={{ padding: "0px", color:'black' }}
            >
              {moment(row.original.sizing_created_at).format(
                "MM/DD/YY"
              )}
            </Button>
  
        ),
      },
      {
        accessorKey: "network_created_at",
        header: "Network",
        muiTableBodyCellProps: ({
          cell,row
        }) => ({
          sx: {
            backgroundColor: PROCUREMENT_STAGES_STATUS_COLOR[row.original.network_status] , 
            padding: '10px', // Add padding for internal spacing
            border: '1.5px solid #fff', 
          }
        }),
        Cell: ({ row }) => (
        
            <Button
              type="link"
              onClick={() =>
                navigate(`/procurement?PK=${row.original.PK}&stage=Network`)
              }
              style={{ padding: "0px", color:'black' }}
            >
              {moment(row.original.network_created_at).format(
                "MM/DD/YY"
              )}
            </Button>
        
        ),
      },
      {
        accessorKey: "commvault_created_at",
        header: "CommVault",
        muiTableBodyCellProps: ({
          cell, row
        }) => ({
          sx: {
            backgroundColor:  PROCUREMENT_STAGES_STATUS_COLOR[row.original.commvault_status] , 
            padding: '10px', // Add padding for internal spacing
            border: '1.5px solid #fff', 
          }
        }),
        Cell: ({ row }) => (
        
            <Button
              type="link"
              onClick={() =>
                navigate(`/procurement?PK=${row.original.PK}&stage=CommVault`)
              }
              style={{ padding: "0px",color:'black' }}
            >
              {moment(row.original.commvault_created_at).format(
                "MM/DD/YY"
              )}
            </Button>
          
        ),
      },
      {
        accessorKey: "site_created_at",
        header: "Site",
        muiTableBodyCellProps: ({
          cell, row
        }) => ({
          sx: {
            backgroundColor:  PROCUREMENT_STAGES_STATUS_COLOR[row.original.site_status] , 
            padding: '10px', // Add padding for internal spacing
            border: '1.5px solid #fff',
          }
        }),
        Cell: ({ row }) => (
            <Button
              type="link"
              onClick={() =>
                navigate(
                  `/procurement?PK=${row.original.PK}&stage=Site`
                )
              }
              style={{ padding: "0px", color:'black' }}
            >
              {moment(row.original.site_created_at).format("MM/DD/YY")}
            </Button>
           
        ),
      },
      {
        accessorKey: "_",
        header: "BoM",
      },
      {
        accessorKey: "_",
        header: "Quote",
      },
      {
        accessorKey: "_",
        header: "Pay Order",
      },
      {
        accessorKey: "_",
        header: "Delivery",
      },

      {
        accessorKey: "-",
        header: "Action",
        Cell: ({ row }) => (
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteProcurement(row.original)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const initialState = {
    density: "compact",
    columnPinning: { left: ["mrt-row-actions", "procurement_name"] },
    pagination: { pageSize: 10 },
  };

  console.log("procurementData", procurementData);

  return (
    <Row gutter={[10, 10]} align="center" style={{ marginTop: "30px" }}>
      <Col span={22}>
        <MaterialReactTable
          columns={columns}
          data={procurementData}
          enableColumnPinning={true}
          initialState={initialState}
          enableStickyHeader={true}
        />
      </Col>
    </Row>
  );
};

export default ProcurementList;
