import { fetchAuthSession } from '@aws-amplify/auth';
import AWS from 'aws-sdk';

export async function publishToSns(
  topicArn: string,
  messageBody: any,
  messageAttributes?: { [key: string]: { 'DataType': string; 'StringValue': string } },
  retries: number = 3,
  region: any = process.env.REACT_APP_AWS_DEFAULT_REGION
): Promise<{
  success: boolean;
  data: any;
  error: null | undefined | Error;
}> {
  let attempt = 0;
  let snsResponse: any = null;

  // Get AWS credentials using Amplify
  const session = await fetchAuthSession();

  // Configure AWS SDK with obtained credentials
  AWS.config.update({
    credentials: session.credentials,
    region,
  });

  // Create an SNS service object
  const sns = new AWS.SNS();
  console.log(JSON.stringify(messageAttributes))
  // Prepare parameters for publishing the message
  const params = {
    TopicArn: topicArn,
    Message: JSON.stringify(messageBody),
    MessageAttributes: messageAttributes || {},
  };

  while (attempt < retries) {
    try {
      // Publish the message to SNS topic
      snsResponse = await sns.publish(params).promise();
      console.log('SNS response: '+ JSON.stringify(snsResponse))
      break;
    } catch (error) {
      console.error('Error publishing message to SNS topic:');
      console.error(error);
      if (attempt < retries - 1) {
        console.debug(`Retrying... Attempt ${attempt + 1}/${retries}`);
        attempt++;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      } else {
        return {
          error: Error(`SNS publish failed. Retries ${attempt + 1}`),
          success: false,
          data: null,
        };
      }
    }
  }
  return {
    error: null,
    success: true,
    data: snsResponse,
  };
}
