import ProcLayout from "components/common/ProcurementComps/ProcLayout";
import DataSection from "./DataSection";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useProcurementStore from "store/procurement_store";
import NetworkDataSection from "../NetworkSection";
import CommVaultSection from "components/CommVaultSection/CommVaultSection";
import SiteSection from "components/SiteSection/SiteSection";

const SizingSection = () => {
  const [searchParams] = useSearchParams();
  const PK = searchParams.get("PK");
  const stage_name = searchParams.get("stage");
  const { fetchProcurementData } = useProcurementStore((state) => ({
    fetchProcurementData: state.fetchProcurementData,
  }));
  useEffect(() => {
    console.log("stage_name", stage_name);
    fetchProcurementData(PK, stage_name);
  }, [PK]);

  const StageComponent = ({ stageName }) => {
    switch (stageName) {
      case "Sizing":
        return <DataSection />;
      case "Network":
        return <NetworkDataSection />;
      case "CommVault":
        return <CommVaultSection />;
        case "Site":
          return <SiteSection />;
      default:
        return <DataSection />;
    }
  };

  return (
    <div>
      <ProcLayout>
        <StageComponent stageName={stage_name} />
      </ProcLayout>
    </div>
  );
};

export default SizingSection;
