import React from "react";
import NetworkActionSection from "./NetworkActionSection";
import DataComponent from "components/common/DataComponent";

const NetworkDataSection = () => {
  return (
    <div
      id="site-section"
      style={{
        backgroundColor: "white",
        padding: "40px",
        border: "3px solid #f6f6f6",
        borderRadius: "10px",
      }}
    >
      <DataComponent />
      <NetworkActionSection />
    </div>
  );
};

export default NetworkDataSection;
