import { Col, Modal, Row, Table } from "antd";
import _ from "lodash";


const PrismData = ({existingClusterInfo}) => {

    let nodes_data = existingClusterInfo?.nodes ? [...existingClusterInfo.nodes] : []
        if (existingClusterInfo?.node_specs_totals) {
            nodes_data.push(existingClusterInfo.node_specs_totals)
        }
        nodes_data = nodes_data.filter((node)=>  node.Node !== "usage_stats%" && node.Node !== "provisioned_data" )
  const columns = [
    {
      title: "NODE",
      dataIndex: "Node",
      key: "Node",
    },
    {
      title: "NUM CPU CORES",
      dataIndex: "num_cpu_cores",
      key: "num_cpu_cores",
    },
    {
      title: "RAM (GB)",
      dataIndex: "memory_capacity_gb",
      key: "memory_capacity_gb",
    },
    {
        title: "NUM DISKS",
        dataIndex: "host_disks_reference_list",
        key: "host_disks_reference_list",
      },
    {
      title: "1Gbps 4 PORT NICs",
      dataIndex: "host_nics_id_list",
      key: "host_nics_id_list",
    },
    
  ];

  return (
    <Row gutter={[20, 0]} align={"middle"} justify={"center"}>
      <Col span={24}>
        <Table
            dataSource={nodes_data}
            columns={columns}
          pagination={false}
          bordered
          size="small"
          style={{ marginBottom: "20px" }}
        />
      </Col>
    </Row>
  );
};

export default PrismData;
