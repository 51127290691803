import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import DataComponent from "components/common/DataComponent";
import React, { useState } from "react";
const style = {
  display: "flex",
  flexDirection: "column",
  gap: 15,
  fontWeight: 450,
};
const SiteSection = () => {
  const [value, setValue] = useState("");
  const [date, setDate] = useState(null);
  const handleRadioOnChange = (e) => {
    setValue(e.target.value);
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset to start of the day
    return current && current < today;
  };
  const handleDataPickerChange = (date, dateString) => {
    setDate(dateString);
  };

  return (
    <>
    <Form>
      <div
        id="site-section"
        style={{
          backgroundColor: "white",
          padding: "40px",
          border: "3px solid #f6f6f6",
          borderRadius: "10px",
        }}
      >
    <DataComponent />
    {/* <br></br> */}
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Radio.Group
              style={style}
              onChange={handleRadioOnChange}
              defaultValue={1}
            >
              <Radio value={1}>Site can support the additional resources</Radio>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                 gap:'10%'
                }}
              >
                <Radio value={2}>
                  Site needs work to support the additional resources
                </Radio>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Typography>Select ETA : </Typography>
                  <DatePicker
                    disabled={value !== 2}
                    // value={date}
                    disabledDate={disabledDate}
                    onChange={handleDataPickerChange}
                    placeholder="Select Date"
                  />
                </div>
              </div>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space style={{ marginTop: "35px" }}>
              <Form.Item>
                <Button type="primary">Assessment Complete</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary">Validate</Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </div>
    </Form>
    </>
  );
};

export default SiteSection;
