import React from "react";
import { Col, Divider, Row, Table, Typography } from "antd";
import useProcurementStore from "store/procurement_store";

const { Text } = Typography;

const DataComponent = () => {
  const { demandIntakeData } = useProcurementStore((state) => ({
    demandIntakeData: state.demandIntakeData,
  }));

  const data = demandIntakeData.existing;

  const resourcesColumns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of vCPUs",
      dataIndex: "est_vcpus",
      key: "est_vcpus",
    },
    {
      title: "RAM (in GB)",
      dataIndex: "est_memory",
      key: "est_memory",
    },
    {
      title: "Storage (in GB)",
      dataIndex: "est_diskspace",
      key: "est_diskspace",
    },
  ];

  const resourcesData = [
    {
      key: "resources",
      name: <Text strong>Resources</Text>,
      est_memory: <Text>{data?.est_memory}</Text>,
      est_vcpus: <Text>{data?.est_vcpus}</Text>,
      est_diskspace: <Text>{data?.est_diskspace}</Text>,
    },
  ];

  return (
    <div>
      <Text strong> Sizing Details :</Text>
      <Row style={{ marginBottom: "30px", marginTop:'10px' }}>
        <Col span={18}>
          <Table
            dataSource={resourcesData}
            columns={resourcesColumns}
            pagination={false}
            bordered
            size="small"
            style={{ marginBottom: "20px" }}
          />
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default DataComponent;
